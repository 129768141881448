import { EmeraldSideNavigation } from '@emerald-react/side-navigation';
import React, { useCallback, useContext } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AdminPanelSettingsIcon from '@material-ui/icons/AdminPanelSettings';
import { createPortal } from 'react-dom';
import {
  Avatar,
  useSidebarPinState,
  Link as BsLink,
  useSidebarOpenState,
} from '@backstage/core-components';
import SidebarLogo from './SidebarLogo';
import { SidebarSearchModal } from '../../search/SidebarSearchModal';
import { SearchModal } from '../../search/SearchModal';
import { useSidebarCloseState } from './providers/SidebarCloseStateProvider';
import useMutationObserver from './hooks/useMutationObserver';
import { FrontendConfigContext } from '../../../providers/FrontendConfigProvider';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import ProvisioningCenterIcon from '../icons/provisioningCenterIcon';
import PromptStoreIcon from '../icons/promptStoreIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '@media (max-width: 768px)': {
        'main > header': {
          paddingLeft: '74px !important',
        },
      },
      ':root': {
        '--eds-sn-color-primary': theme.palette.sideNavigation.primary,
        '--eds-sn-color-secondary': theme.palette.sideNavigation.secondary,
        '--eds-sn-color-active': theme.palette.sideNavigation.colorActive,
        '--eds-sn-color-background': `${theme.palette.sideNavigation.colorBackground} !important`,
        '--eds-sn-color-border': theme.palette.sideNavigation.colorBorder,
        '--eds-sn-color-hover-background':
          theme.palette.sideNavigation.colorHoverBackground,
        '--eds-sn-color-active-background':
          theme.palette.sideNavigation.colorActiveBackground,
        '--eds-sn-color-icon': theme.palette.sideNavigation.colorIcon,
      },
    },

    rootDiv: {
      height: '100vh',
      '& .emerald-nav-items::-webkit-scrollbar': {
        width: theme.spacing(1.25),
      },
      '& .emerald-side-navigation.collapsed .emerald-nav-items': {
        overflow: 'hidden',
      },
      '& .emerald-nav-item .emerald-nav-separator': {
        margin: '0px',
      },
      '& .emerald-nav-footer-wrapper': {
        zIndex: 1,
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        gap: '16px',
        width: '100%',
        marginLeft: '-6px',
      },
      '& .pin-icon': {
        bottom: 0,
        top: 'unset !important',
        zIndex: 2,
      },

      // '& .collapsed': {
      //   '& .username': {
      //     display: 'none',
      //   },
      // },
    },

    menuButton: {
      display: 'none !important',
      [theme.breakpoints.down(768)]: {
        display: 'block !important',
        position: 'absolute !important',
        top: '30px',
        zIndex: 102,
        left: '24px',
        color: 'white !important',
      },
    },

    leftAvatar: {
      width: 'max-content',
      display: 'flex',
      alignItems: 'center',

      gap: '10px',
      '& div': {
        width: '32px',
        height: '32px',
        fontSize: '14px',
        cursor: 'pointer',

        color: theme.palette.primary.main,
      },
      '& span': {
        fontSize: '14px',
      },
      '&:hover': {
        '& div': {
          backgroundColor: theme.palette.background.tertiary,
          color: theme.palette.primary.main,
        },
      },
    },
    buttonContainer: {
      width: '100%',
    },
    mobileButton: {
      minWidth: '0px',
      padding: '6px',
    },
  }),
);

const SideNav = ({
  isAdminFlag,
  userName,
  profile,
}: {
  isAdminFlag: boolean | null;
  userName: string;
  profile: any;
}) => {
  const classes = useStyles();

  const { isPinned, toggleSidebarPinState, isMobile } = useSidebarPinState();
  // const { isOpen, setOpen } = useSidebarOpenState();
  // const { onClose } = useSidebarCloseState();

  /* All Assets Group */
  const allAssetsGroup = {
    label: 'Reusable Code/API',
    icon: 'code',
    children: [
      {
        label: 'Components',
        link: '/code-library',
        icon: 'widgets',
        linkComponent: Link,
      },
      {
        label: 'All APIs',
        link: '/API',
        icon: 'api',
        linkComponent: Link,
      },
      {
        label: 'Register An Api',
        link: '/API/register-api',
        icon: 'add_circle_outline',
        linkComponent: Link,
      },
      {
        label: 'Storybook',
        link: 'https://docs.emerald.cbre.com/?path=/docs/introduction--docs',
        icon: 'menu_book',
        linkTarget: '_blank',
      },
      {
        label: 'Software Templates',
        link: '/software-templates',
        icon: 'description',
        linkComponent: Link,
      },
    ],
  };

  /* Developer Utilities */
  const developerUtilities = {
    label: 'Dev Tools',
    icon: 'developer_board',

    children: [
      {
        icon: 'code',
        link: '/api-mock',
        label: 'Mock APIs',
        linkComponent: Link,
      },
      {
        link: '/utility-tools',
        label: 'Utility Tools',
        icon: 'build',
        linkComponent: Link,
      },
      {
        icon: 'visibility',
        link: '/visual-regression',
        label: 'Visual Regression',
        linkComponent: Link,
      },
    ],
  };

  const aboutDevXGroup = {
    label: 'About Dev-X',
    icon: 'more',
    separator: true,

    children: [
      {
        icon: 'help_outline',
        link: '/help',
        label: 'User Guide',
        linkComponent: Link,
      },
      {
        icon: 'feedback',
        link: '/feedback',
        label: 'Feedback',
        linkComponent: Link,
      },
      {
        icon: 'note',
        link: '/release-notes',
        label: 'Release Notes',
        linkComponent: Link,
      },
    ],
  };

  const controlledSideBarItems = [
    {
      label: 'Services & Tools',
      link: '/toolbox',
      icon: 'home_repair_service',
      linkComponent: Link,
    },
    {
      label: 'Information Exchange',
      link: '/information-exchange',
      icon: 'question_answer',
      linkComponent: Link,
    },
    {
      label: 'Data Insights',
      link: '/data-insights',
      icon: 'insights',
      linkComponent: Link,
    },
    {
      label: 'POC Hub',
      link: '/poc-hub',
      icon: 'lightbulb_circle', // Similar to POC icon
      linkComponent: Link,
    },
    {
      label: 'Provisioning Center',
      link: '/provisioning-center',
      iconComponent: <ProvisioningCenterIcon />,
      linkComponent: Link,
    },
    {
      label: 'Prompt Store',
      link: '/prompt-store',
      iconComponent: <PromptStoreIcon />,
      linkComponent: Link,
    },
    {
      label: 'AI in SDLC',
      link: '/ai-in-sdlc',
      icon: 'auto_awesome', // Similar to AI in SDLC icon
      linkComponent: Link,
    },
  ];

  const { frontendConfigData } = useContext(FrontendConfigContext);
  const splitTreatments = useSplitTreatments({
    names: ['devx-ui-feature-enable'],
  });

  const navItems = [
    { label: 'Home', link: '/catalog', icon: 'home', linkComponent: Link },
    { ...allAssetsGroup },
    {
      label: 'My Assets',
      link: '/catalog/my-assets',
      icon: 'folder_open',
      linkComponent: Link,
    },
    { ...developerUtilities },
    ...controlledSideBarItems.filter(
      (item: any) =>
        frontendConfigData === undefined ||
        (splitTreatments.factory &&
          splitTreatments?.treatments['devx-ui-feature-enable'].config &&
          JSON.parse(
            splitTreatments?.treatments['devx-ui-feature-enable'].config,
          ).sidebarFeatures.includes(item.label)),
    ),
    { ...aboutDevXGroup },
  ];

  const devxHeader =
    document.querySelector('.emerald-app-content .HeaderTopTabs header') ??
    null;

  const AdminButton = () => {
    const navigate = useNavigate();
    const isOpen = useMutationObserver(isPinned);

    const navigateToAdmin = () => {
      navigate('admin');
    };
    return (
      <>
        <div className={classes.buttonContainer}>
          {isOpen ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AdminPanelSettingsIcon />}
              onClick={navigateToAdmin}
              fullWidth
            >
              Admin
            </Button>
          ) : (
            <Button
              onClick={navigateToAdmin}
              variant="contained"
              color="primary"
              className={classes.mobileButton}
            >
              <AdminPanelSettingsIcon />
            </Button>
          )}
        </div>
      </>
    );
  };

  const SearchModalWrapper = React.memo(() => {
    return (
      <SidebarSearchModal>
        {({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}
      </SidebarSearchModal>
    );
  });

  const Header = () => {
    return (
      <>
        <SidebarLogo />
        <SearchModalWrapper />
      </>
    );
  };

  const Footer = () => {
    const isOpen = useMutationObserver(isPinned);
    return (
      <>
        <AdminButton />
        <BsLink
          to="/settings"
          underline="none"
          component={NavLink}
          className={classes.leftAvatar}
        >
          <Avatar displayName={userName} picture={profile?.picture} />
          <span>{isOpen ? userName : null}</span>
          {/* <span className="username"> {userName} </span> */}
        </BsLink>
      </>
    );
  };

  const handleToggle = (expanded: boolean) => {
    //  setOpen(expanded);
    if (isMobile && expanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  };

  const handlePinChange = useCallback(
    (pinned: boolean) => {
      toggleSidebarPinState();
      console.log(pinned);
    },
    [toggleSidebarPinState],
  );

  const location = useLocation();

  return (
    <div className={classes.rootDiv}>
      {devxHeader &&
        createPortal(
          <IconButton
            onClick={() => toggleSidebarPinState()}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>,
          devxHeader,
        )}

      <EmeraldSideNavigation
        items={navItems}
        nestedViewEvent="hover"
        header={<Header />}
        footer={<Footer />}
        pinExpansion={isPinned}
        activePath={location.pathname}
        onToggle={handleToggle}
        onPinChange={handlePinChange}
        // {...(isMobile && {
        //   closeOutsideClick: true,
        //   onClose: () => onClose(),
        // })}
      ></EmeraldSideNavigation>
    </div>
  );
};

export default SideNav;
